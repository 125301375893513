export const APP = "/app";
export const STAFF = "/staff";
export const ORGANIZATION = `${APP}/organization`;

export const ADMIN = `${APP}/admin`

export const CULTIVATION = `${APP}/cultivation`;
export const PROCESSING = `${APP}/processing`;
export const FINANCE = `${APP}/finance`;

export const STRAINS = `${APP}/strains`;
export const FORM_FACTORS = `${APP}/form-factors`;
export const USER_MANAGEMENT = `${APP}/users`;
export const MAINTENANCE_REQUESTS = `${APP}/maintenance-requests`;

export const CULTIVATION_DASHBOARD = `${CULTIVATION}/dashboard`;
export const CULTIVATION_ANALYTICS = `${CULTIVATION}/analytics`;
export const HARVEST_GROUPS = `${CULTIVATION}/harvest-groups`;
export const HARVEST_GROUP_ROUTE = (groupId: string) =>
  `${HARVEST_GROUPS}/${groupId}`;
export const HARVEST_GROUP_TEMPLATES = `${HARVEST_GROUPS}/templates`;
export const HARVEST_GROUP_HISTORY = `${HARVEST_GROUPS}/history`;
export const RECIPES = `${CULTIVATION}/recipes`;
export const ZONES = `${CULTIVATION}/zones`;
export const ZONE_ROUTE = (zoneId: string | number) => `${ZONES}/${zoneId}`;
export const ZONE_HARVESTGROUP_ROUTE = (
  zoneId: string | number,
  hgId: string
) => `${ZONES}/${zoneId}/harvest-groups/${hgId}`;
export const SCHEDULING = `${CULTIVATION}/scheduling`;

export const MGMT = `/mgmt`;

export const TABLEAU = `${STAFF}/tableau`;
export const STAFF_CONTACTS = `${STAFF}/contacts`;
export const STAFF_ORGANIZATIONS = `${STAFF}/organizations`;
export const STAFF_USERS = `${STAFF}/users`;

export const PROCESSING_ANALYTICS = `${PROCESSING}/analytics`;
export const PROCESSING_GROUPS = `${PROCESSING}/processing-groups`;
export const PROCESSING_SCHEDULING = `${PROCESSING}/scheduling`;
export const PROCESSING_GROUP_TEMPLATES = `${PROCESSING_GROUPS}/templates`;
export const PROCESSING_GROUP_TEMPLATES_NEW = `${PROCESSING_GROUPS}/templates/new`;
export const PROCESSING_GROUP_TEMPLATE_FLOWCHART = (id: string) =>
  `${PROCESSING_GROUPS}/templates/${id}`;
export const PROCESSING_GROUP_HISTORY = `${PROCESSING_GROUPS}/history`;
export const PROCESSING_GROUP_ROUTE = (groupId: string) =>
  `${PROCESSING_GROUPS}/${groupId}`;
export const PROCESSING_GROUP_NEW = `${PROCESSING_GROUPS}/new`;
export const PROCESSING_GROUP_STRAIN_ROUTE = (
  groupId: string,
  strainId: string
) => `${PROCESSING_GROUP_ROUTE(groupId)}/strains/${strainId}`;

export const PROCESSING_GROUP_STRAIN_ADJUSTMENTS = (
  groupId: string,
  strainId: string
) => `${PROCESSING_GROUP_ROUTE(groupId)}/strains/${strainId}/adjustments`;

export const PROCESSING_JOB = (groupId: string, jobId: string) =>
  `${PROCESSING_GROUP_ROUTE(groupId)}/jobs/${jobId}`;

export const ANALYTICS_HARVEST_FORECAST = `${CULTIVATION_ANALYTICS}/harvest-forecast`;
export const ANALYTICS_CULTIVATION_ACTIVITY = `${CULTIVATION_ANALYTICS}/cultivation-activity`;
export const ANALYTICS_STRAIN_PERFORMANCE = `${CULTIVATION_ANALYTICS}/strain-performance`;
export const ANALYTICS_NUTRIENT_REPORT = `${CULTIVATION_ANALYTICS}/nutrient-report`;

export const ANALYTICS_GPM_DASHBOARD = `${PROCESSING_ANALYTICS}/gpm-dashboard`;
export const ANALYTICS_WORKER_PERFORMANCE = `${PROCESSING_ANALYTICS}/worker-performance`;
export const ANALYTICS_TRIMMER_STRAIN_OPTIMIZATION = `${PROCESSING_ANALYTICS}/trimmer-strain-optimization`;

export const FINANCE_DASHBOARD = `${FINANCE}/dashboard`;
export const FINANCE_ANALYTICS_ALLOCATION_BREAKDOWN = `${FINANCE}/analytics/allocation-breakdown`;

export const ENVIRONMENTAL = `${CULTIVATION}/environmental`
export const ENVIRONMENTAL_UPLOAD = `${ENVIRONMENTAL}/dashboard`
export const ENVIRONMENTAL_SETTINGS = `${ENVIRONMENTAL}/target-values`
